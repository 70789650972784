<template>
  <v-container fluid>
    <v-card-title>레포트 분류 설정</v-card-title>
    <v-card-subtitle>
      앱에서 레포트 구분값이 될 항목을 선택해주세요.
    </v-card-subtitle>
    <v-row>
      <v-col cols="5">
        <v-card outlined>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="listReportCategory"
            show-select
            disable-sort
            hide-default-footer
            :items-per-page="1000"
            @click:row="(row) => edit(row)"
          >
            <template v-if="changeIndex" v-slot:header.data-table-select />
            <template v-if="changeIndex" v-slot:body>
              <draggable v-model="listReportCategory" tag="tbody">
                <tr v-for="(data, index) in listReportCategory" :key="index">
                  <td><v-icon>mdi-menu</v-icon></td>
                  <td class="text-center">{{ data.order }}</td>
                  <td class="text-center">{{ data.name }}</td>
                  <td class="text-center">{{ data.inUse }}</td>
                </tr>
              </draggable>
            </template>
            <template v-slot:item.inUse="{ item }">
              {{ item.inUse ? '사용' : '사용 안함' }}
            </template>
            <template v-slot:footer>
              <v-divider />
              <v-container fluid class="row" no-gutters>
                <v-spacer />
                <v-btn v-if="!changeIndex" class="mr-3" @click="deleteReportCategory">
                  선택 분류삭제
                </v-btn>
                <v-btn v-else class="mr-3" color="red" dark @click="cancelOrder">
                  취소
                </v-btn>
                <v-btn :color="changeIndex ? 'green' : null" dark @click="changeIndex ? setOrder() : (changeIndex = !changeIndex)">
                  {{ changeIndex ? '완료' : '순서 변경하기' }}
                </v-btn>
              </v-container>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col>
        <v-divider style="border-color: black" />
        <v-row no-gutters>
          <v-col cols="2" class="ml-5 my-auto">
            <b>분류명</b>
          </v-col>
          <v-col class="py-3">
            <v-text-field v-model="name" outlined dense hide-details />
          </v-col>
        </v-row>
        <v-divider />
        <v-row no-gutters>
          <v-col cols="2" class="ml-5 my-auto">
            <b>사용 여부</b>
          </v-col>
          <v-col class="py-3">
            <v-radio-group v-model="inUse" row>
              <v-radio label="사용" :value="true" />
              <v-radio label="사용 안함" :value="false" />
            </v-radio-group>
          </v-col>
        </v-row>
        <v-divider />
        <v-row no-gutters>
          <v-col cols="2" class="ml-5 my-auto">
            <b>등록 레포트 수</b>
          </v-col>
          <v-col class="py-3">
            <v-text-field outlined dense hide-details disabled filled :value="Column ? Column.length : ''" />
          </v-col>
        </v-row>
        <v-divider />
        <v-row no-gutters>
          <v-col cols="2" class="ml-5 my-auto">
            <b>등록 일시</b>
          </v-col>
          <v-col class="py-3">
            <v-text-field outlined dense hide-details disabled filled :value="createdAt" />
          </v-col>
        </v-row>
        <v-divider />
        <v-row no-gutters>
          <v-col cols="2" class="ml-5 my-auto">
            <b>수정 일시</b>
          </v-col>
          <v-col class="py-3">
            <v-text-field outlined dense hide-details disabled filled :value="updatedAt" />
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer />
          <v-btn class="mx-2" color="red" dark @click="cancel">
            취소하기
          </v-btn>
          <v-btn v-if="id" class="mx-2" color="primary" dark @click="updateReportCategory">
            수정하기
          </v-btn>
          <v-btn v-else class="mx-2" color="#ffd600" @click="createReportCategory">
            저장하기
          </v-btn>
          <v-spacer />
        </v-card-actions>
        <v-divider style="border-color: black" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent, onBeforeMount, reactive, toRefs } from '@vue/composition-api'
import draggable from 'vuedraggable'

export default defineComponent({
  name: 'ReportCategory',

  components: { draggable },

  setup(props, { root }) {
    const state = reactive({
      changeIndex: false,
      selected: [],
      headers: [
        { text: '노출순서', value: 'order', align: 'center' },
        { text: '분류명', value: 'name', align: 'center' },
        { text: '사용여부', value: 'inUse', align: 'center' },
      ],
      listReportCategory: [],
      id: null,
      order: null,
      name: null,
      inUse: true,
      Column: null,
      createdAt: null,
      updatedAt: null,
    })

    const getListReportCategory = async () => {
      state.listReportCategory = await root.$store.dispatch('report/getListReportCategory')
      state.listReportCategory.sort((a, b) => a.order - b.order)
    }

    const createReportCategory = async () => {
      try {
        await root.$store.dispatch('report/createReportCategory', {
          name: state.name,
          inUse: state.inUse,
          order: state.listReportCategory ? state.listReportCategory.length + 1 : 1,
        })

        getListReportCategory()
        cancel()
      } catch (err) {
        console.error(err)
      }
    }

    const cancel = () => {
      state.id = null
      state.name = null
      state.inUse = true
      state.Column = null
      state.updatedAt = null
      state.createdAt = null
    }

    const cancelOrder = async () => {
      await getListReportCategory()
      state.changeIndex = false
    }

    const setOrder = async () => {
      let count = 1
      for (const i in state.listReportCategory) {
        await root.$store.dispatch('report/updateReportCategory', {
          id: Number(state.listReportCategory[i].id),
          order: count++,
          name: state.listReportCategory[i].name,
          inUse: state.listReportCategory[i].inUse,
        })
      }
      await getListReportCategory()
      state.changeIndex = false
    }

    const edit = (row) => {
      state.id = row.id
      state.order = row.order
      state.name = row.name
      state.inUse = row.inUse
      state.Column = row.Column
      state.updatedAt = row.updatedAt
      state.createdAt = row.createdAt
    }

    const updateReportCategory = async () => {
      try {
        await root.$store.dispatch('report/updateReportCategory', {
          id: state.id,
          order: state.order,
          name: state.name,
          inUse: state.inUse,
        })

        getListReportCategory()
        cancel()
      } catch (err) {
        console.error(err)
      }
    }

    const deleteReportCategory = async () => {
      if (!confirm('정말 삭제하시겠습니까?')) return

      for (const i in state.selected) {
        await root.$store.dispatch('report/deleteReportCategory', {
          id: Number(state.selected[i].id),
        })
      }
      await getListReportCategory()
      let count = 1
      for (const i in state.listReportCategory) {
        await root.$store.dispatch('report/updateReportCategory', {
          id: Number(state.listReportCategory[i].id),
          order: count++,
          name: state.listReportCategory[i].name,
          inUse: state.listReportCategory[i].inUse,
        })
      }
      await getListReportCategory()
    }

    onBeforeMount(() => {
      getListReportCategory()
    })

    return {
      ...toRefs(state),
      edit,
      cancel,
      cancelOrder,
      setOrder,
      createReportCategory,
      updateReportCategory,
      deleteReportCategory,
    }
  },
})
</script>
